import { version } from './version';

export const environment = {
  production: true,
  envName: 'prod',
  apiUrl: '',
  wsUrl: 'admin.vshosting.cloud',
  cloudDnsWsUrl: 'admin.vshosting.cloud',
  googleSiteKey: '6Lfi3H0UAAAAAFXzwHVk244Xfm1Cg5sPHKbVsqFn',
  cdnAuthRedirectUrl: 'https://cdn.vshosting.cloud/authorize',
  cloudMailAuthRedirectUrl: 'http://localhost:4003/authorize',
  release: version,
  enableCDNS: true,
  enableCDN: true,
  enableCloudMail: false,
  forceDevWsProtocol: 'wss',
  mojeIdConnectRedirectUrl: 'https://admin.vshosting.cloud/external-auth/mojeid/connect',
  mojeIdLoginRedirectUrl: 'https://admin.vshosting.cloud/external-auth/mojeid/login',
  googleConnectRedirectUrl: 'https://admin.vshosting.cloud/external-auth/google/connect',
  googleLoginRedirectUrl: 'https://admin.vshosting.cloud/external-auth/google/login',
  stripePublishableKey: 'pk_live_51PHQflP3qvSvTb9o3njaEQrGLdeVphPCagcYDN7oDYitxGqahIbSnyxRdxTLScuJvfLRekQkE1kU6mLU6CzjzHSC000nBg8joc',
  appleIdConnectRedirectUrl: 'https://admin.vshosting.cloud/external-auth/apple/connect',
  appleIdLoginRedirectUrl: 'https://admin.vshosting.cloud/external-auth/apple/login'
};
